export const nbaTeams = {
  "ATLANTA HAWKS": "ATL",
  "BOSTON CELTICS": "BOS",
  "BROOKLYN NETS": "BKN",
  "BROOKLYN NETS/PHOENIX SUNS": "BKN/PHX",
  "CHARLOTTE HORNETS": "CHA",
  "CHICAGO BULLS": "CHI",
  "CLEVELAND CAVALIERS": "CLE",
  "DALLAS MAVERICKS": "DAL",
  "DENVER NUGGETS": "DEN",
  "DETROIT PISTONS": "DET",
  "GOLDEN STATE WARRIORS": "GSW",
  "HOUSTON ROCKETS": "HOU",
  "INDIANA PACERS": "IND",
  "LOS ANGELES CLIPPERS": "LAC",
  "LOS ANGELES LAKERS": "LAL",
  "MEMPHIS GRIZZLIES": "MEM",
  "MIAMI HEAT": "MIA",
  "MILWAUKEE BUCKS": "MIL",
  "MINNESOTA TIMBERWOLVES": "MIN",
  "NEW ORLEANS PELICANS": "NOP",
  "NEW YORK KNICKS": "NYK",
  "OKLAHOMA CITY THUNDER": "OKC",
  "ORLANDO MAGIC": "ORL",
  "PHILADELPHIA 76ERS": "PHI",
  "PHOENIX SUNS": "PHX",
  "PORTLAND TRAIL BLAZERS": "POR",
  "SACRAMENTO KINGS": "SAC",
  "SAN ANTONIO SPURS": "SAS",
  "TORONTO RAPTORS": "TOR",
  "UTAH JAZZ": "UTA",
  "WASHINGTON WIZARDS": "WAS",
}

export const nbaTeamColor = {
  "ATLANTA HAWKS": 'rgb(225,68,52)',
  "BOSTON CELTICS": 'rgb(0,122,51)',
  "BROOKLYN NETS": 'rgb(0,0,0)',
  "BROOKLYN NETS/PHOENIX SUNS": 'rgb(150,75,0)',
  "CHARLOTTE HORNETS": 'rgb(0,120,140)',
  "CHICAGO BULLS": 'rgb(206,17,65)',
  "CLEVELAND CAVALIERS": "rgb(134,0,56)",
  "DALLAS MAVERICKS": "rgb(0,83,188)",
  "DENVER NUGGETS": "rgb(13,34,64)",
  "DETROIT PISTONS": "rgb(29,66,138)",
  "GOLDEN STATE WARRIORS": 'rgb(255,199,44)',
  "HOUSTON ROCKETS": "rgb(206,17,65)",
  "INDIANA PACERS": 'rgb(253,187,48)',
  "LOS ANGELES CLIPPERS": 'rgb(200,16,46)',
  "LOS ANGELES LAKERS": "rgb(85,37,130)",
  "MEMPHIS GRIZZLIES": "rgb(93,118,169)",
  "MIAMI HEAT": "rgb(152,0,46)",
  "MILWAUKEE BUCKS": 'rgb(0,71,27)',
  "MINNESOTA TIMBERWOLVES": 'rgb(35,97,146)',
  "NEW ORLEANS PELICANS": "rgbRGB: (0,22,65)",
  "NEW YORK KNICKS": "rgb(0,107,182)",
  "OKLAHOMA CITY THUNDER": "rgb(0,125,195)",
  "ORLANDO MAGIC": "rgb(0,125,197)",
  "PHILADELPHIA 76ERS": 'rgb(237,23,76)',
  "PHOENIX SUNS": "rgb(29,17,96)",
  "PORTLAND TRAIL BLAZERS": "rgb(224,58,62)",
  "SACRAMENTO KINGS": 'rgb(91,43,130)',
  "SAN ANTONIO SPURS": 'rgb(196,206,211)',
  "TORONTO RAPTORS": "rgb(6,25,34)",
  "UTAH JAZZ": "rgb(0,43,92)",
  "WASHINGTON WIZARDS": "rgb(0,43,92)",
}

export const wnbaTeams = {
  "WASHINGTON MYSTICS": "WAS",
}
export const wnbaTeamColor = {
  "WASHINGTON MYSTICS": 'rgb(224,58,62)',
}

export const nbaPositions = {
  "POINT GUARD": "PG",
  "SHOOTING GUARD": "SG",
  "SMALL FORWARD": "SF",
  "POWER FORWARD": "PF",
  "CENTER": "C",
}

export const nbaPctMins = {
  "fg": 300,
  "threePtFg": 82,
  "ft": 125,
}