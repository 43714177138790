export const pastMembers = [
  {
    age: 34,
    assists: 235,
    attempted_field_goals: 862,
    attempted_free_throws: 334,
    attempted_three_point_field_goals: 230,
    blocks: 67,
    defensive_rebounds: 296,
    games_played: 47,
    games_started: 47,
    made_field_goals: 483,
    made_free_throws: 307,
    made_three_point_field_goals: 93,
    minutes_played: 1672,
    name: "Kevin Durant",
    offensive_rebounds: 17,
    personal_fouls: 99,
    points: 1366,
    positions: [ "POWER FORWARD" ],
    season: 2023,
    slug: "duranke01",
    steals: 34,
    team: "BROOKLYN NETS/PHOENIX SUNS",
    turnovers: 156,
  },
  {
    age: 28,
    assists: 324,
    attempted_field_goals: 1086,
    attempted_free_throws: 218,
    attempted_three_point_field_goals: 378,
    blocks: 37,
    defensive_rebounds: 205,
    games_played: 54,
    games_started: 54,
    made_field_goals: 549,
    made_free_throws: 201,
    made_three_point_field_goals: 152,
    minutes_played: 1886,
    name: "Kyrie Irving",
    offensive_rebounds: 52,
    personal_fouls: 140,
    points: 1451,
    positions: [ "POINT GUARD" ],
    season: 2021,
    slug: "irvinky01",
    steals: 76,
    team: "BROOKLYN NETS",
    turnovers: 130,
  },
  {
    age: 26,
    assists: 205,
    attempted_field_goals: 748,
    attempted_free_throws: 152,
    attempted_three_point_field_goals: 244,
    blocks: 13,
    defensive_rebounds: 223,
    games_played: 64,
    games_started: 64,
    made_field_goals: 378,
    made_free_throws: 141,
    made_three_point_field_goals: 104,
    minutes_played: 1832,
    name: "Malcolm Brogdon",
    offensive_rebounds: 65,
    personal_fouls: 102,
    points: 1001,
    positions: [ "SHOOTING GUARD" ],
    season: 2019,
    slug: "brogdma01",
    steals: 46,
    team: "MILWAUKEE BUCKS",
    turnovers: 91,
  },
  {
    age: 27,
    assists: 527,
    attempted_field_goals: 1598,
    attempted_free_throws: 400,
    attempted_three_point_field_goals: 886,
    blocks: 15,
    defensive_rebounds: 362,
    games_played: 79,
    games_started: 79,
    made_field_goals: 805,
    made_free_throws: 363,
    made_three_point_field_goals: 402,
    minutes_played: 2700,
    name: "Stephen Curry",
    offensive_rebounds: 68,
    personal_fouls: 161,
    points: 2375,
    positions: [ "POINT GUARD" ],
    season: 2016,
    slug: "curryst01",
    steals: 169,
    team: "GOLDEN STATE WARRIORS",
    turnovers: 262,
  },
  {
    age: 24,
    assists: 374,
    attempted_field_goals: 1433,
    attempted_free_throws: 750,
    attempted_three_point_field_goals: 334,
    blocks: 105,
    defensive_rebounds: 594,
    games_played: 81,
    games_started: 81,
    made_field_goals: 731,
    made_free_throws: 679,
    made_three_point_field_goals: 139,
    minutes_played: 3119,
    name: "Kevin Durant",
    offensive_rebounds: 46,
    personal_fouls: 143,
    points: 2280,
    positions: [ "SMALL FORWARD" ],
    season: 2013,
    slug: "duranke01",
    steals: 116,
    team: "OKLAHOMA CITY THUNDER",
    turnovers: 280,
  },
  {
    age: 35,
    assists: 892,
    attempted_field_goals: 985,
    attempted_free_throws: 225,
    attempted_three_point_field_goals: 291,
    blocks: 12,
    defensive_rebounds: 234,
    games_played: 81,
    games_started: 81,
    made_field_goals: 499,
    made_free_throws: 211,
    made_three_point_field_goals: 124,
    minutes_played: 2660,
    name: "Steve Nash",
    offensive_rebounds: 34,
    personal_fouls: 108,
    points: 1333,
    positions: [ "POINT GUARD" ],
    season: 2010,
    slug: "nashst01",
    steals: 42,
    team: "PHOENIX SUNS",
    turnovers: 295,
  },
  {
    age: 34,
    assists: 717,
    attempted_field_goals: 851,
    attempted_free_throws: 210,
    attempted_three_point_field_goals: 246,
    blocks: 10,
    defensive_rebounds: 204,
    games_played: 74,
    games_started: 74,
    made_field_goals: 428,
    made_free_throws: 196,
    made_three_point_field_goals: 108,
    minutes_played: 2484,
    name: "Steve Nash",
    offensive_rebounds: 19,
    personal_fouls: 108,
    points: 1160,
    positions: [ "POINT GUARD" ],
    season: 2009,
    slug: "nashst01",
    steals: 55,
    team: "PHOENIX SUNS",
    turnovers: 248,
  },
  {
    age: 33,
    assists: 898,
    attempted_field_goals: 962,
    attempted_free_throws: 245,
    attempted_three_point_field_goals: 381,
    blocks: 5,
    defensive_rebounds: 254,
    games_played: 81,
    games_started: 81,
    made_field_goals: 485,
    made_free_throws: 222,
    made_three_point_field_goals: 179,
    minutes_played: 2780,
    name: "Steve Nash",
    offensive_rebounds: 28,
    personal_fouls: 113,
    points: 1371,
    positions: [ "POINT GUARD" ],
    season: 2008,
    slug: "nashst01",
    steals: 53,
    team: "PHOENIX SUNS",
    turnovers: 295,
  },
  {
    age: 28,
    assists: 263,
    attempted_field_goals: 1341,
    attempted_free_throws: 551,
    attempted_three_point_field_goals: 173,
    blocks: 62,
    defensive_rebounds: 571,
    games_played: 78,
    games_started: 78,
    made_field_goals: 673,
    made_free_throws: 498,
    made_three_point_field_goals: 72,
    minutes_played: 2820,
    name: "Dirk Nowitzki",
    offensive_rebounds: 122,
    personal_fouls: 171,
    points: 1916,
    positions: [ "POWER FORWARD" ],
    season: 2006,
    slug: "nowitdi01",
    steals: 52,
    team: "DALLAS MAVERICKS",
    turnovers: 167,
  },
  {
    age: 31,
    assists: 826,
    attempted_field_goals: 1056,
    attempted_free_throws: 279,
    attempted_three_point_field_goals: 342,
    blocks: 12,
    defensive_rebounds: 286,
    games_played: 79,
    games_started: 79,
    made_field_goals: 541,
    made_free_throws: 257,
    made_three_point_field_goals: 150,
    minutes_played: 2796,
    name: "Steve Nash",
    offensive_rebounds: 47,
    personal_fouls: 120,
    points: 1489,
    positions: [ "POINT GUARD" ],
    season: 2006,
    slug: "nashst01",
    steals: 61,
    team: "PHOENIX SUNS",
    turnovers: 276,
  },
  {
    age: 28,
    assists: 248,
    attempted_field_goals: 1042,
    attempted_free_throws: 444,
    attempted_three_point_field_goals: 292,
    blocks: 24,
    defensive_rebounds: 182,
    games_played: 79,
    games_started: 79,
    made_field_goals: 524,
    made_free_throws: 403,
    made_three_point_field_goals: 123,
    minutes_played: 2638,
    name: "Reggie Miller",
    offensive_rebounds: 30,
    personal_fouls: 193,
    points: 1574,
    positions: [ "SHOOTING GUARD" ],
    season: 1994,
    slug: "millere01",
    steals: 119,
    team: "INDIANA PACERS",
    turnovers: 175,
  },
  {
    age: 24,
    assists: 631,
    attempted_field_goals: 1006,
    attempted_free_throws: 292,
    attempted_three_point_field_goals: 211,
    blocks: 7,
    defensive_rebounds: 178,
    games_played: 75,
    games_started: 74,
    made_field_goals: 529,
    made_free_throws: 263,
    made_three_point_field_goals: 93,
    minutes_played: 2728,
    name: "Mark Price",
    offensive_rebounds: 48,
    personal_fouls: 98,
    points: 1414,
    positions: [ "POINT GUARD" ],
    season: 1989,
    slug: "pricema01",
    steals: 115,
    team: "CLEVELAND CAVALIERS",
    turnovers: 212,
  },
  {
    age: 31,
    assists: 467,
    attempted_field_goals: 1672,
    attempted_free_throws: 453,
    attempted_three_point_field_goals: 237,
    blocks: 57,
    defensive_rebounds: 595,
    games_played: 76,
    games_started: 75,
    made_field_goals: 881,
    made_free_throws: 415,
    made_three_point_field_goals: 98,
    minutes_played: 2965,
    name: "Larry Bird",
    offensive_rebounds: 108,
    personal_fouls: 157,
    points: 2275,
    positions:[ "SMALL FORWARD" ],
    season: 1988,
    slug: "birdla01",
    steals: 125,
    team: "BOSTON CELTICS",
    turnovers: 213,
  },
  {
    age: 30,
    assists: 566,
    attempted_field_goals: 1497,
    attempted_free_throws: 455,
    attempted_three_point_field_goals: 225,
    blocks: 70,
    defensive_rebounds: 558,
    games_played: 74,
    games_started: 73,
    made_field_goals: 786,
    made_free_throws: 414,
    made_three_point_field_goals: 90,
    minutes_played: 3005,
    name: "Larry Bird",
    offensive_rebounds: 124,
    personal_fouls: 185,
    points: 2076,
    positions: [ "SMALL FORWARD" ],
    season: 1987,
    slug: "birdla01",
    steals: 135,
    team: "BOSTON CELTICS",
    turnovers: 240,
  }
]

export const pastWNBAMembers = [
  {
    age: 31,
    assists: 68,
    attempted_field_goals: 427,
    attempted_free_throws: 117,
    attempted_three_point_field_goals: 121,
    blocks: 39,
    defensive_rebounds: 207,
    games_played: 31,
    games_started: 31,
    made_field_goals: 220,
    made_free_throws: 114,
    made_three_point_field_goals: 52,
    minutes_played: 901,
    name: "Elena Delle Donne",
    offensive_rebounds: 48,
    personal_fouls: 32,
    points: 606,
    positions: [ "SMALL FORWARD" ],
    season: 2019,
    slug: "delleel01w",
    steals: 19,
    team: "DALLAS MAVERICKS",
    turnovers: 30,
  }
]

export const statMinimums = {
  fgMakes: {
    "82": 300,
    "72": 264
  },
  threePtMakes: {
    "82": 82,
    "72": 72
  },
  ftMakes: {
    "82": 125,
    "72": 110
  }
}
  